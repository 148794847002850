import React, { useState, useEffect } from "react";
import useT  from '../../Traduction';
import { TbTrashXFilled, TbSquareRoundedPlusFilled } from "react-icons/tb";
import { Button } from "react-bootstrap";
import { exclude_columns as exclude_column_choices} from "../CreateFormatComposants/ExcludeColumnChoices"

const fileOutputObject = {
  catalog_name: "",
  output_type: "",
  sort: false,
  sort_by: "",
  exclude_column: [],
  country: "FR",
  language: "FR",
  finished_product: false
}

const FileOutput = ({ fileOutput, setFileOutput, setFileOutputConfirmed, formatName }) => {
  const t = useT();
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setFileOutput((prevFileOutput) => {
      const newFileOutput = {
        ...fileOutputObject,
        "catalog_name": formatName,
        "output_type": "csv",
        "sort": false,
        "sort_by": "",
        "exclude_column": [],
        "country": "FR",
        "language": "FR",
        "finished_product": false
      };

      return {
        ...prevFileOutput,
        0: newFileOutput,
      };
    });
  }, [formatName, setFileOutput]);

  const [fileOutputRows, setFileOutputRows] = useState([fileOutputObject]);

  const handleAddFileOutputRow = () => {
    setFileOutputRows((prevRows) => [...prevRows, fileOutputObject]);
  };

  const handleConfirmFileOutput = () => {
    if (Object.keys(fileOutput).length === 0) {
      setErrorMessage(t("You_must_create_at_least_one_output_file"));
    } else {
      let hasOutputFile = true;

      for (const key in fileOutput) {
          const element = fileOutput[key];
          if (element.output_type === "") {
            hasOutputFile = false;
          }
      }
      if (hasOutputFile) {
          setFileOutputConfirmed(true)
          setErrorMessage("");
      } else {
        setErrorMessage(t("You_must_select_an_output_file_type"));
      }
    }
  }

  const handleCreateFileOutput = (key, value) => {
    setFileOutput((prevFileOutput) => {
      const newFileOutput = {
        ...fileOutputObject,
        "catalog_name": value.toUpperCase(),
      };

      return {
        ...prevFileOutput,
        [key]: newFileOutput,
      };
    });
  };

  const handleFileOutputChange = (key, subkey, value) => {
    setFileOutput((prevFileOutput) => {
      if (!prevFileOutput[key]) {
        prevFileOutput[key] = { ...fileOutputObject };
      }
      if (subkey === "sort") {
        prevFileOutput[key][subkey] = value;
        if (!value) {
          prevFileOutput[key]["sort_by"] = "";
        }
      } else {
        prevFileOutput[key][subkey] = value;
      }
      return { ...prevFileOutput };
    });
  };

  const handleRemoveFileOutput = (index) => {
    setFileOutput((prevFileOutput) => {
      const updatedFileOutput = { ...prevFileOutput };
      if (updatedFileOutput[index]) {
        delete updatedFileOutput[index];
      }
      setFileOutputRows((prevRows) => prevRows.filter((_, i) => i !== index - 1));
      return updatedFileOutput;
    });
  };

  const handleExcludeColumnSelectChange = (index, selectedOptions, e ) => {
    const selectedValues = Array.from(selectedOptions)
    .filter((option) => option.selected)
    .map((option) => option.value);
    setFileOutput((prevFileOutput) => {
      const updatedFileOutput = { ...prevFileOutput };
      if (updatedFileOutput[index]) {
        updatedFileOutput[index].exclude_column = selectedValues;
      }
      return updatedFileOutput;
    });
  };

  return (
    <div className="container-fluid">
      <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
      <p className=""> 
        {t("Please_indicate_the_desired_output_file")}
      </p>
      <div className="mt-3 table-responsive">
        <table className="table table-hover text-center table-mapping-file-custom">
            <colgroup>
                <col className="col-width-10"/>
                <col className="col-width-7"/>
                <col className="col-width-5"/>
                <col className="col-width-7"/>
                <col className="col-width-7"/>
                <col className="col-width-5"/>
                <col className="col-width-5"/>
                <col className="col-width-20"/>
                <col className="col-width-5"/>
            </colgroup>
            <thead className="thead-mapping-file-custom ">
                <tr>
                    <th className="tr-mapping-file-custom">{t("Catalog_name")}</th>
                    <th className="tr-mapping-file-custom">{t("File_type")}</th>
                    <th className="tr-mapping-file-custom">{t("Finished_product")}</th>
                    <th className="tr-mapping-file-custom">{t("Country")}</th>
                    <th className="tr-mapping-file-custom">{t("Language")}</th>
                    <th className="tr-mapping-file-custom">{t("Sort")}</th>
                    <th className="tr-mapping-file-custom">{t("Sort_by")}</th>
                    <th className="tr-mapping-file-custom">{t("Exclude_column")}</th>
                    <th className="tr-mapping-file-custom">{t("Action")}</th>
                </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input 
                    className="form-control"
                    type="text"
                    placeholder={t("Catalog_name")}
                    value={formatName}
                    disabled
                  />
                </td>
                <td>
                  <select 
                    className="form-control form-control-custom"
                    value="csv"
                    disabled
                  >
                    <option className="text-center" selected value="csv">CSV</option>
                  </select>

                </td>
                <td>
                  X
                </td>
                <td>
                  <select
                    className="form-control form-control-custom"
                    value="FR"
                    disabled
                  >
                    <option className="text-center" selected value="FR">{t("France")}</option>
                  </select>
                </td>
                <td>
                  <select
                    className="form-control form-control-custom"
                    value="FR"
                    disabled
                  >
                    <option className="text-center" selected value="FR">{t("French")}</option>
                  </select>
                </td>
                <td>
                  X
                </td>
                <td>
                  X
                </td>
                <td>
                  X
                </td>
                <td>
                  X
                </td>
              </tr>
              {fileOutputRows.map((row, index) => (
                <tr key={index + 1}>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("Catalog_name")}
                      value={fileOutput[index + 1] ? fileOutput[index + 1].catalog_name : ""}
                      onChange={(e) => handleCreateFileOutput(index + 1, e.target.value)}
                    />
                  </td> 
                  {fileOutput[index + 1] && fileOutput[index + 1].catalog_name !== "" ? (
                    <td>
                      <select
                        className="form-control form-control-custom"
                        onChange={(e) => handleFileOutputChange(index + 1, "output_type", e.target.value)}
                        value={fileOutput[index + 1] ? fileOutput[index + 1].output_type : ""}
                      >
                        <option className="text-center" disabled value="">{t("Select_file_type")}</option>
                        <option className="text-center" value="csv">CSV</option>
                        <option className="text-center" value="dbf">DBF</option>
                      </select>
                    </td>
                  ) : (
                    <td>X</td>
                  )}
                  {fileOutput[index + 1] && fileOutput[index + 1].catalog_name !== "" ? (
                    <td>
                      <input
                        type="checkbox"
                        checked={fileOutput[index + 1] ? fileOutput[index + 1].finished_product : false}
                        onChange={(e) => handleFileOutputChange(index + 1, "finished_product", e.target.checked)}
                      />
                    </td>
                  ) : (
                    <td>X</td>
                  )}
                  {fileOutput[index + 1] && fileOutput[index + 1].catalog_name !== "" ? (
                    <td>
                      <select
                        className="form-control form-control-custom"
                        onChange={(e) => handleFileOutputChange(index + 1, "country", e.target.value)}
                        value={fileOutput[index + 1] ? fileOutput[index + 1].country : ""}
                      >
                        <option className="text-center" value="BE">{t("Belgium")}</option>
                        <option className="text-center" value="CH">{t("Switzerland")}</option>
                        <option className="text-center" value="DE">{t("Germany")}</option>
                        <option className="text-center" value="EN">{t("England")}</option>
                        <option className="text-center" value="ES">{t("Spain")}</option>
                        <option className="text-center" value="FR">{t("France")}</option>
                        <option className="text-center" value="IT">{t("Italy")}</option>
                        <option className="text-center" value="LU">{t("Luxembourg")}</option>
                        <option className="text-center" value="NL">{t("Netherlands")}</option>
                      </select>
                    </td>
                  ) : (
                    <td>X</td>
                  )}
                  {fileOutput[index + 1] && fileOutput[index + 1].catalog_name !== "" ? (
                    <td>
                      <select
                        className="form-control form-control-custom"
                        onChange={(e) => handleFileOutputChange(index + 1, "language", e.target.value)}
                        value={fileOutput[index + 1] ? fileOutput[index + 1].language : ""}
                      >
                        <option className="text-center" value="DE">{t("German")}</option>
                        <option className="text-center" value="EN">{t("English")}</option>
                        <option className="text-center" value="ES">{t("Spanish")}</option>
                        <option className="text-center" value="FR">{t("French")}</option>
                        <option className="text-center" value="IT">{t("Italian")}</option>
                        <option className="text-center" value="LU">{t("Luxembourgish")}</option>
                        <option className="text-center" value="NL">{t("Dutch")}</option>
                      </select>
                    </td>
                  ) : (
                    <td>X</td>
                  )}
                  {fileOutput[index + 1] && fileOutput[index + 1].catalog_name !== "" ? (
                    <td>
                      <input
                        type="checkbox"
                        checked={fileOutput[index + 1] ? fileOutput[index + 1].sort : false}
                        onChange={(e) => handleFileOutputChange(index + 1, "sort", e.target.checked)}
                      />
                    </td>
                  ) : (
                    <td>X</td>
                  )}
                  {fileOutput[index + 1] && fileOutput[index + 1].sort ? (
                    <td>
                      <select
                        className="form-control form-control-custom"
                        onChange={(e) => handleFileOutputChange(index + 1, "sort_by", e.target.value)}
                        value={fileOutput[index + 1] ? fileOutput[index + 1].sort_by : ""}
                      >
                        <option className="text-center" disabled value="">{t("Select_sort_by")}</option>
                        <option className="text-center" value="discount_code">{t("Discount_code")}</option>
                        <option className="text-center" value="family">{t("Family")}</option>
                    </select>
                    </td>
                  ) : <td>X</td>}
                    {fileOutput[index + 1] && fileOutput[index + 1].catalog_name !== "" && fileOutput[index + 1].output_type === "dbf" ? (
                    <td>
                      <select
                          multiple
                          className="form-select form-control form-control-custom" 
                          aria-label="multiple select example"
                          onChange={(e) => handleExcludeColumnSelectChange(index + 1, e.target.options, e)}
                          value={fileOutput[index + 1] ? fileOutput[index + 1].exclude_column : []}
                        >
                        {exclude_column_choices.map((choice_value) => (
                          <option key={choice_value} value={choice_value}>
                            {t(`${choice_value}`)}
                          </option>
                        ))}
                      </select>
                    </td>
                  ) : <td>X</td>}
                  <td>
                    <Button className="btn-delete btn-delete-custom-mapping-file" onClick={() => handleRemoveFileOutput(index + 1)}>
                      <TbTrashXFilled size={20} />
                    </Button>
                  </td>
                </tr>
              ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="mt-1 d-flex justify-content-center">
                    <button type="submit" className="btn" onClick={() => handleAddFileOutputRow()}>
                      <TbSquareRoundedPlusFilled size={20} />
                    </button>
                  </td>
                </tr>
            </tbody>
        </table>
      </div>
      {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{errorMessage}</div></div>}
      <div className="mt-5 d-flex justify-content-center">
        <button type="submit" className="btn" onClick={() => handleConfirmFileOutput()}>
          {t("Confirm_output_file")}
        </button>
      </div>
    </div>
  );
};
                        
export default FileOutput;
