import React, { useState, useEffect, useCallback } from 'react';
import useT from '../../components/Traduction'
import { Button } from "react-bootstrap";
import { TbReload } from "react-icons/tb";
import { Auth } from 'aws-amplify';

function DepositTrackingComponent({ userGroup, dataProject }) {
    const t  = useT();
    const [errorMessage, setErrorMessage] = useState("");
    const [jobs, setJobs] = useState([]);
    const [isClicked, setIsClicked] = useState(false);

    const fetchData = useCallback(async () => {
        let url = `${dataProject.startUrl}/list-jobs?username=${dataProject.userAttribute.email}`;
        const response = await fetch(url, {
            headers: {
                "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
                "Content-Type": "application/json",
                },
            method: 'GET'
        });

        if (!response.ok) {
            if (response.status === 401) {
                setErrorMessage(t("Unauthorized"));
                console.log(t("Unauthorized"));
                Auth.signOut()
            } else if (response.status === 0) {
                setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
            } else {
                setErrorMessage(t("Unexpected_error"));
            }
        } else {
            const jsonData = await response.json();
            const resultJob = jsonData.result.jobs
            const resultJobLength = resultJob.length
            if (!resultJob || resultJobLength === 0) {
                setErrorMessage(t("No_tracking_deposit"));
                return;
            }
    
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
            const formattedJobsData = jsonData.result.jobs.map(job => ({
                ...job,
                Created_at: new Date(job.Created_at).toLocaleString('fr-FR', { timeZone: userTimezone }),
                Updated_at: new Date(job.Updated_at).toLocaleString('fr-FR', { timeZone: userTimezone })
            }));
            setJobs(formattedJobsData);
        }
        
    }, [dataProject, t]);
    
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleRefresh = () => {
        setIsClicked(true);
        fetchData();
        setTimeout(() => {
          setIsClicked(false);
        }, 800);
      };    

    if (userGroup === "super-admin" || userGroup === "admin") {
        return (
            <>
                <Button onClick={handleRefresh} className={`${isClicked ? 'clicked' : ''} p-0 mx-1 mt-2 btn-refresh-deposit-tracking`}>
                    <TbReload size={25}/>
                </Button>
                <div className="container-fluid mt-3 d-flex justify-content-center">
                    {errorMessage ? (
                    <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>
                    ) : (
                    jobs.length >= 1 ? (
                        <table className="table table-hover table-bordered shadow">
                        <colgroup>
                            <col className="col-width-10" />
                            <col className="col-width-10" />
                            <col className="col-width-10" />
                            <col className="col-width-30" />
                            <col className="col-width-10" />
                            <col className="col-width-10" />
                            <col className="col-width-10" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="deposit-tracking-col-name">{t("Supplier")}</th>
                                <th className="deposit-tracking-col-name">{t("File_name")}</th>
                                <th className="deposit-tracking-col-name">{t("Status")}</th>
                                <th className="deposit-tracking-col-name">{t("Error_message")}</th>
                                <th className="deposit-tracking-col-name">{t("Username_table")}</th>
                                <th className="deposit-tracking-col-name">{t("Created_at")}</th>
                                <th className="deposit-tracking-col-name">{t("Updated_at")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((item, index) => {
                            let statusColor = "";
                            if (item.Status === "Failed") {
                                statusColor = "text-danger";
                            } else if (item.Status === "In progress") {
                                statusColor = "text-warning";
                            } else if (item.Status === "Success") {
                                statusColor = "text-success";
                            }
                            return (
                                <tr key={index}>
                                    <td>{item.Supplier}</td>
                                    <td>{item.File_name}</td>
                                    <td className={statusColor}>
                                        <b>{t(item.Status)}</b>
                                    </td>
                                    <td>{item.Error_message}</td>
                                    <td>{item.Username}</td>
                                    <td>{item.Created_at}</td>
                                    <td>{item.Updated_at}</td>
                                </tr>
                            );
                            })}
                        </tbody>
                        </table>
                    ) : (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    )
                    )}
                </div>
            </>
        );          
    } else {
        return (
            <div className="container-fluid mt-5 d-flex justify-content-center">
                <h1 className="alert alert-warning mt-4 fw-bold">{t("Required_rights_to_perform_this_action")}</h1>
            </div>
        )
    }
}

export default DepositTrackingComponent;