import React from 'react';
import ButtonLink from '../ButtonLink';
import useT from '../Traduction';

function HomeComponent({ userGroup }) {
    const t = useT();

    return (
        <div >
            <div className="home">
                {(userGroup === "super-admin" || userGroup === "admin") ? (
                    <div className="button-container">
                    <div className="column">
                        <h3>{t('Supplier')}</h3>
                        <ul className="text-center btn-list">
                            <li className="btn-element">
                                <ButtonLink link="/creation-fournisseur" linkText={t('Add_supplier')} className="button-large" />
                            </li>
                            <li className="btn-element">
                                <ButtonLink link="/format-fournisseur" linkText={t('Formats_by_supplier')} className="button-large" />
                            </li>
                            <li className="btn-element">
                                <ButtonLink link="/catalogues-fournisseur" linkText={t('Catalogs_by_supplier')} className="button-large" />
                            </li>
                        </ul>
                    </div>
                    <div className="column">
                        <h3>{t('Format')}</h3>
                        <ul className="text-center btn-list">
                            <li className="btn-element">
                                <ButtonLink link="/creation-format" linkText={t('Create_format')} className="button-large" />
                            </li>
                            
                        </ul>
                    </div>
                    <div className="column">
                        <h3>{t('Catalog')}</h3>
                        <ul className="text-center btn-list">
                            <li className="btn-element">
                                <ButtonLink link="/upload-file" linkText={t('Deposit_file')} className="button-large" />
                            </li>
                            <li className="btn-element">
                                <ButtonLink link="/suivi-depot" linkText={t("Deposit_tracking")} className="button-large" />
                            </li>
                            
                        </ul>
                    </div>
                    <div className="column">
                        <h3>{t('Referential')}</h3>
                        <ul className="text-center btn-list">
                            <li className="btn-element">
                                <ButtonLink link="/referentiel" linkText={t('Get_reference')} className="button-large" />
                            </li>
                        </ul>
                    </div>
                </div>
                ) : (
                    <ul className="text-center btn-list">
                        <li className="btn-element">
                            <ButtonLink link="/referentiel" linkText={t('Get_reference')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/catalogues-fournisseur" linkText={t('Catalogs_by_supplier')} className="button-large" />
                        </li>
                    </ul>
                )}
            </div>
        </div>
    )
}

export default HomeComponent
