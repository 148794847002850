import React, { useState, useEffect } from 'react';
import useT from '../../Traduction';

function SheetSelector({ sheetNames, onSheetsSelected }) {
  const t = useT();
  const [selectedSheets, setSelectedSheets] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectAll) {
      setSelectedSheets(sheetNames);
    } else {
      setSelectedSheets([]);
    }
  }, [selectAll, sheetNames]);

  const handleCheckboxChange = (sheetName) => {
    setSelectedSheets((prevSelectedSheets) =>
      prevSelectedSheets.includes(sheetName)
        ? prevSelectedSheets.filter((name) => name !== sheetName)
        : [...prevSelectedSheets, sheetName]
    );
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
  };

  const handleConfirm = () => {
    onSheetsSelected(selectedSheets, selectAll);
  };

  return (
    <div className="mb-3 mt-3 text-center">
      <label className="my-1 fw-bold">{t("Select_sheets")}:</label>
      <div>
        {sheetNames.length > 1 && (
          <div className="">
            <input
              type="checkbox"
              id="selectAll"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <label className="mx-2" htmlFor="selectAll">
              {t("All")}
            </label>
          </div>
        )}
        {sheetNames.map((sheetName) => (
          <div key={sheetName} className="">
            <input
              type="checkbox"
              id={sheetName}
              value={sheetName}
              checked={selectedSheets.includes(sheetName)}
              onChange={() => handleCheckboxChange(sheetName)}
              disabled={selectAll}
            />
            <label className="mx-2" htmlFor={sheetName}>
              {sheetName}
            </label>
          </div>
        ))}
      </div>
      {selectedSheets.length > 0 && (
        <button type="submit" className="btn mt-3" onClick={handleConfirm}>
          {t("Validate")}
        </button>
      )}
    </div>
  );
}

export default SheetSelector;