async function CallListSuppliers(dataProject) {
  const baseUrl = `https://api.datacatalog.${dataProject.environment}.gestimag.com/list-suppliers?limit=250`;
  let dataSuppliers = [];
  let loop = true
  let lastEvaluatedKey = null;
  while (loop) {
    let url;
    if (lastEvaluatedKey) {
      url = `${baseUrl}&old_last_evaluated_key=${encodeURIComponent(lastEvaluatedKey)}`;
    } else {
      url = baseUrl
    }
    let response = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",
    },
    });
    if (!response.ok) {
      if (response.status === 500) {
          return "Unexpected_error_when_retrieving_suppliers"
      } else if (response.status === 0) {
          return "Connection_error";
      } else if (response.status === 401) {
          console.log("Unauthorized");
          return "Unauthorized";
      } else if (response.status === 404) {
        return "No_supplier_found";
      } else {
          return "Unexpected_error_when_retrieving_suppliers";
      }
    }
    try {
      let data = await response.json();
      for (const supplierObject of data.result.suppliers) {
        dataSuppliers.push(supplierObject.supplier);
      }
      if (data.result.hasOwnProperty('last_evaluated_key') && data.result.last_evaluated_key !== null) {
         lastEvaluatedKey = data.result.last_evaluated_key;
      } else {
         lastEvaluatedKey = null;
         loop = false
      }
    } catch (error) {
      return "Unexpected_error_when_retrieving_suppliers";
    }
  }
  if (dataSuppliers.length === 0) { 
    return "No_supplier_found";
  } else {
    return dataSuppliers;
  }
}
  
  export default CallListSuppliers;