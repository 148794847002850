import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import useT from '../Traduction'

function IsAuth({ children }) {
  const t = useT();
  const navigate = useNavigate();
  const timeout = 2700000; // 45 minutes = 2700000

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        Auth.signOut();
        navigate('/connexion');
      }, timeout);
    }
    const handleInteraction = () => {
      resetTimeout();
    }

    Auth.currentAuthenticatedUser()
      .then(user => {
        resetTimeout();
        window.addEventListener('mousemove', handleInteraction);
        window.addEventListener('keypress', handleInteraction);
      })
      .catch(error => {
        // User is not authenticated
        // navigate('/connexion');
      });

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keypress', handleInteraction);
    };
  }, [navigate, t]);

  return children;
}

export default IsAuth;
