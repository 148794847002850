export const fields = [
    {
        name: "Reference*",
        in_db: "reference",
        type: "string"
    },
    {
        name: "Supplier*",
        in_db: "supplier",
        type: "string"
    },
    {
        name: "Reference",
        in_db: "reference_in_db",
        type: "string",
        matching: [
            "reference",
        ]
    },
    {
        name: "Reference1",
        in_db: "reference1",
        type: "string",
        matching: [
            "reference1",
        ]
    },
    {
        name: "Reference2",
        in_db: "reference2",
        type: "string",
        matching: [
            "reference2",
        ]
    },
    {
        name: "Reference3",
        in_db: "reference3",
        type: "string",
        matching: [
            "reference3",
        ]
    },
    {
        name: "Supplier",
        in_db: "supplier_in_db",
        type: "string",
        matching: [
            "fournisseur",
            "Supplier"
        ]
    },
    {
        name: "Designation",
        in_db: "designation",
        type: "string",
        matching: [
            "designation",
        ]
    },
    {
        name: "Designation1",
        in_db: "designation1",
        type: "string",
        matching: [
            "designation1"
        ]
    },
    {
        name: "Designation2",
        in_db: "designation2",
        type: "string",
        matching: [
            "designation2",
        ]
    },
    {
        name: "Designation3",
        in_db: "designation3",
        type: "string",
        matching: [
            "designation3"
        ]
    },
    {
        name: "Brand",
        in_db: "brand",
        type: "string",
        matching: [
            "marque",
            "brand"
        ]
    },
    {
        name: "Selling_price_et",
        in_db: "selling_price_et",
        type: "float",
        matching: [
            "prix de vente ht",
            "pv ht",
            "selling price et"
        ]
    },
    {
        name: "Selling_price_it",
        in_db: "selling_price_it",
        type: "float",
        matching: [
            "prix de vente ttc",
            "pv ttc",
            "selling price it"
        ]
    },
    {
        name: "Purchase_price_it",
        in_db: "purchase_price_it",
        type: "float",
        matching: [
            "prix d achat ht",
            "pa ht",
            "purchase price it"
        ]
    },
    {
        name: "Purchase_price_et",
        in_db: "purchase_price_et",
        type: "float",
        matching: [
            "prix d achat ttc",
            "pa ttc",
            "purchase price et",
            "prix d'achat ht"
        ]
    },
    {
        name: "Recommended_public_price_et",
        in_db: "recommended_public_price_et",
        type: "float",
        matching: [
            "prix public recommande ht",
        ]
    },
    {
        name: "Recommended_public_price_it",
        in_db: "recommended_public_price_it",
        type: "float",
        matching: [
            "prix public recommande ttc",
        ]
    },
    {
        name: "Price_sb",
        in_db: "price_sb",
        type: "float",
        matching: [
            "price sb",
            "prix bf"
        ]
    },
    {
        name: "Bar_code",
        in_db: "bar_code",
        type: "string",
        matching: [
            "code barre",
            "bar code"
        ]
    },
    {
        name: "Bar_code_2",
        in_db: "bar_code2",
        type: "string",
        matching: [
            "code barre2",
            "bar code2"
        ]
    },
    {
        name: "Bar_code_3",
        in_db: "bar_code3",
        type: "string",
        matching: [
            "code barre3",
            "bar code3"
        ]
    },
    {
        name: "Bar_code_4",
        in_db: "bar_code4",
        type: "string",
        matching: [
            "code barre4",
            "bar code4"
        ]
    },
    {
        name: "Family",
        in_db: "family",
        type: "string",
        matching: [
            "famille",
            "family"
        ]
    },
    {
        name: "Sub_family",
        in_db: "sub_family",
        type: "string",
        matching: [
            "sous famille",
            "sub family"
        ]
    },
    {
        name: "Sub_family_2",
        in_db: "sub_family_2",
        type: "string",
        matching: [
            "sous famille2",
            "sub family2"
        ]
    },
    {
        name: "Sub_family_3",
        in_db: "sub_family_3",
        type: "string",
        matching: [
            "sous famille3",
            "sub family3"
        ]
    },
    {
        name: "Activity",
        in_db: "activity",
        type: "string",
        matching: [
            "activite",
            "activity",
        ]
    },
    {
        name: "Discount_code",
        in_db: "discount_code",
        type: "string",
        matching: [
            "code remise",
            "discount code"
        ]
    },
    {
        name: "Color",
        in_db: "color",
        type: "string",
        matching: [
            "couleur",
            "color"
        ]
    },
    {
        name: "Description",
        in_db: "description",
        type: "string",
        matching: [
            "description"
        ]
    },
    {
        name: "Materials",
        in_db: "materials",
        type: "string",
        matching: [
            "materiaux", 
            "materials"
        ]
    },
    {
        name: "Country_origin",
        in_db: "country_origin",
        type: "string",
        matching: [
            "pays d origine",
            "origin country"
        ]
    },
    {
        name: "Customs_code",
        in_db: "customs_code",
        type: "string",
        matching: [
            "customs code",
            "code de douane",
        ]
    },
    {
        name: "Manufacturer_reference",
        in_db: "manufacturer_reference",
        type: "string",
        matching: [
            "manufacturer reference",
            "reference fabricant"
        ]
    },
    {
        name: "Replacement",
        in_db: "replacement",
        type: "string",
        matching: [
            "replacement",
            "remplacement"
        ]
    },
    {
        name: "Size",
        in_db: "size",
        type: "string",
        matching: [
            "size",
            "taille"
        ]
    },    
    {
        name: "Ref_type",
        in_db: "ref_type",
        type: "string",
        matching: [
            "ref type",
            "reference type",
            "type"
        ]
    },
    {
        name: "Url_product_sheet",
        in_db: "url_product_sheet",
        type: "string",
        matching: [
            "url fiche produit",
            "url product sheet"
        ]
    },
    {
        name: "Url_Photo",
        in_db: "url_Photo",
        type: "string",
        matching: [
            "url photo"
        ]
    },
    {
        name: "Conditioning",
        in_db: "conditioning",
        type: "int",
        matching: [
            "conditioning",
            "conditionnement"
        ]
    },
    {
        name: "Year",
        in_db: "year",
        type: "int",
        matching: [
            "year",
            "annee"
        ]
    },
    {
        name: "Eco_tax",
        in_db: "eco_tax",
        type: "int",
        matching: [
            "eco tax"
        ]
    },
    {
        name: "Vat_rate",
        in_db: "vat_rate",
        type: "int",
        matching: [
            "taux de tva",
            "vat rate"
        ]
    },
    {
        name: "Length",
        in_db: "length",
        type: "float",
        matching: [
            "length",
            "longueur"
        ]
    },
    {
        name: "Weight",
        in_db: "weight",
        type: "float",
        matching: [
            "weight",
            "poids"
        ]
    },
    {
        name: "Volume",
        in_db: "volume",
        type: "float",
        matching: [
            "volume"
        ]
    },
    {
        name: "Width",
        in_db: "width",
        type: "float",
        matching: [
            "largeur",
            "width"
        ]
    },
    {
        name: "Height",
        in_db: "height",
        type: "float",
        matching: [
            "height",
            "hauteur"
        ]
    }
]