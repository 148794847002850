import React, { useState, useEffect } from 'react';
import useT from '../../components/Traduction'
import { Storage } from "aws-amplify";
import uuid4 from "uuid4";
import { Auth } from 'aws-amplify';
import CallListSuppliers from "../ListSuppliers/CallListSuppliers"

function FormFileRepository({ userGroup, dataProject }) {
  const t  = useT();
  const [completeMessage, setCompleteMessage] = useState("");
  const [progressionMessage, setProgressionMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [listSupplierError, setListSupplierError] = useState("");
  const id = uuid4();
  const now = new Date();
  const timestampISO = now.toISOString();
  const [supplierNameList, setSupplierNameList] = useState([]);
  useEffect(() => {
    const fetchListSupplierAsync = async () => {
      const response = await CallListSuppliers(dataProject);
      if (typeof(response) === "string") {
        setListSupplierError(t(response))
        if (response === "Unauthorized") {
          Auth.signOut()
        }
        return []
      } else if (Array.isArray(response)) {
        setSupplierNameList(response)
        return response;
      }
    }
    fetchListSupplierAsync().then(supplierList => {
      setSupplierNameList(supplierList);
    });
  }, [dataProject, t]);

    const uploadFile = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setCompleteMessage("");
    setProgressionMessage("")
    if (userGroup === "super-admin" || userGroup === "admin") {
      const supplier = supplierInput
      const formatName = document.getElementById("format_name").value;
      const key = `${supplierInput}/${dataProject.userAttribute.email}/${timestampISO}#${id}#NAME#${formatName}`
      const file = document.getElementById("file_repository").files[0];
      const fileType = file.type
      const allowedExtensions = /\.(csv|txt|xlsx|xls)$/i;

      if (supplier === "") {
        setErrorMessage(t("Please_enter_a_supplier"));
      } else if (!allowedExtensions.exec(file.name.toLowerCase())) {
        setErrorMessage(t("invalid_file_extension"));
      } else if (fileType === "" && formatName === "") {
        setErrorMessage(t("Please_select_a_format_and_extension"))
      } else if (fileType === "") {
        setErrorMessage(t("Please_select_an_extension"))
      } else if (formatName === "") {
        setErrorMessage(t("Please_select_a_format"))
      } else {
        setCompleteMessage("");

        if (
          fileType !== "text/csv" &&
          fileType !== "text/plain" &&
          fileType !== "application/vnd.ms-excel" &&
          fileType !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          setErrorMessage(`${t("Extension_not_supported")} : ${file.name}`);
        }
      
        try {
          const FileName = `${key}/${file.name}`
          await Storage.put(FileName, file, {
            resumable: true,
            progressCallback: (progress) => {
              setCompleteMessage("");
              setProgressionMessage(`${t('Uploading')} ${t('in_progress')}: ${calculatePercent(progress.loaded, progress.total)}`);
            },
            completeCallback: (event) => {
              setProgressionMessage("");
              setCompleteMessage(`${t("Drop_off_successfully")} : ${file.name}`);
            },
            errorCallback: async (error) => {
              setCompleteMessage("");
              setProgressionMessage("");
              setErrorMessage(t("Unexpected_error"));
              // Retry
              try {
                await Storage.put(FileName, file, {
                  resumable: true,
                  progressCallback: (progress) => {
                    setCompleteMessage("");
                    setProgressionMessage(`${t('Uploading')} ${t('in_progress')}: ${calculatePercent(progress.loaded, progress.total)}`);
                  },
                  completeCallback: (event) => {
                    setProgressionMessage("");
                    setCompleteMessage(`${t("Drop_off_successfully")} : ${file.name}`);
                  },
                  errorCallback: (error) => {
                    setCompleteMessage("");
                    setProgressionMessage("");
                    setErrorMessage(t("Failed_to_upload_try_again"));
                  }
                });
              } catch (error) {
                setErrorMessage(t("Failed_to_upload_try_again"));
              }
            }
          });
        } catch (error) {
          console.log(error);
          setErrorMessage(t("Failed_to_upload_try_again"));
        }
      }
    } else {
      setErrorMessage(t("Required_rights_to_perform_this_action"));
    }
  };

  const [formats, setFormats] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState('');
  const [supplierInput, setSupplierInput] = useState('');
  const [extensionInput, setExtensionInput] = useState('');
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);


  const handleInputSupplier = (event) => {
    setCompleteMessage("")
    setProgressionMessage("")
    setErrorMessage("");
    setUploadVisible(false)
    setIsSelectVisible(false)
    const inputSupplier = event.target.value.toUpperCase();
    const supplierForFormat = inputSupplier;
    setSupplierInput(supplierForFormat)
  };

  const handleInputFileRepository = () => {
    setCompleteMessage("")
    setProgressionMessage("")
    setErrorMessage("");
    setUploadVisible(false)
    setIsSelectVisible(false)
    setSelectedFormat("");
    setFormats([]);
    const handleFile = document.getElementById("file_repository").files[0];
    const splitByPoint = handleFile.name.split('.')
    const extension = splitByPoint[splitByPoint.length - 1]
    setExtensionInput(extension.toLowerCase())
  };

  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
    setUploadVisible(true)
  };
  if (listSupplierError) {
    return (
      <div className="container-fluid mt-5 d-flex justify-content-center">
        {listSupplierError && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{listSupplierError}</div></div>}
      </div>
    )
  } else {
    return (
      <div className="container-fluid mt-5 d-flex justify-content-center">
        <div className="boxed">
          <form onSubmit={uploadFile} className="form-control text-center shadow p-3 mb-5 bg-body rounded form-style">
            <div className="row gx-5">
              <div className="col-md-12 my-3">
                <label htmlFor="supplier" className="mb-2 fw-bold">
                {t("Supplier")} :
                </label>
                <select
                  className="form-control mt-2 mb-3"
                  value={supplierInput}
                  onChange={handleInputSupplier}
                >
                  <option className="text-center" disabled value="">{t("Select_supplier")}</option>
                  {supplierNameList.map((supplierName, index) => (
                    <option className="text-center" key={index} value={supplierName}>{supplierName}</option>
                  ))}
                </select>
                <label htmlFor="file_repository" className="mb-2 fw-bold">
                {t('Choose_a_file_to_upload')} :
                </label>
                <input onChange={handleInputFileRepository} type="file" name="file_repository" id="file_repository" required placeholder={t("File_name")} accept=".csv, .CSV, .xls, .XLS, .xlsx, .XLSX, .txt, .TXT" className="form-control mt-2 mb-3 input-file-repository" />
                <button type="button" className="btn mt-3" onClick={() => {
                  fetchFormats(setFormats, setErrorMessage, setCompleteMessage, t, dataProject, extensionInput, setIsSelectVisible, supplierInput);
                  }} disabled={extensionInput === "" || supplierInput === ""}>
                  {t('Search_for_matching_formats')}  
                </button>

                {isSelectVisible && (
                  <div>
                    <label htmlFor="format" className="mt-3 fw-bold">
                      {t("Format")} :
                    </label>
                    <select
                      name="format_name"
                      id="format_name"
                      value={selectedFormat}
                      onChange={handleFormatChange}
                      className="form-control mt-3 mb-3"
                    >
                      {formats && formats.length <= 0 ? (
                        <>
                          <option value="" className="text-center">{t("No_corresponding_format")}</option>
                        </>
                      ) : (
                        <>
                          <option value="" className="text-center">{t("Select_format")}</option>
                          {formats.map(format => (
                            <option key={format.name} value={format.name} className="text-center">
                              {format.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                )}
                {completeMessage && <div className="alert alert-success mt-4 fw-bold">{completeMessage}</div>}
                {progressionMessage && <div className="alert alert-warning mt-4 fw-bold">{progressionMessage}</div>}
                {errorMessage && <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>}
              </div>
            </div>
            {uploadVisible ? (
              <button type="submit" className="btn">
                {t('Upload')}
              </button>
            ) : null}
          </form>
        </div>
      </div>
    );
  }
}

function fetchFormats(setFormats, setErrorMessage, setCompleteMessage, t, dataProject, extensionInput, setIsSelectVisible, supplierInput) {
  setCompleteMessage("")
  setErrorMessage("");
  var newExtensionInput = extensionInput.toLowerCase();
  if (
    newExtensionInput !== "xlsx" &&
    newExtensionInput !== "xls" &&
    newExtensionInput !== "txt" &&
    newExtensionInput !== "csv"
  ) {
    setErrorMessage(`${t("File_extension_type")} csv, txt, xls, xlsx.`);
  }
  let url = `${dataProject.startUrl}/list-formats?file_type=${newExtensionInput}`;
  fetch(url, {
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",
    },
    method: 'GET'
  })
    .then(async response => {
      let dataResult = "";
      try {
        const data = await response.json();
        dataResult = data.result;
      } catch (error) {
        setErrorMessage(t("Unexpected_error"));
      }
      if (!response.ok) {
        if (response.status === 500) {
          setErrorMessage(t("Unexpected_error"))
        } else if (response.status === 401) {
          setErrorMessage(t("Not_authorised"))
        } else if (dataResult.message === "Exception during event parsing") {
          setErrorMessage(`${t("File_extension_type")} csv, txt, xls, xlsx.`);
        } else {
          setErrorMessage(t("Unexpected_error"));
        }
      } else {
        const dataFormats = dataResult.formats;
        const formats = dataFormats.filter(format => {
        const formatName = format.name
          return formatName.toUpperCase().includes(supplierInput);
        });
        setFormats(formats);
      }
    })
    setIsSelectVisible(true)
}

function calculatePercent(loaded, total) {
  let result = (loaded / total) * 100;
  return result.toFixed(1) + "%";
}

export default FormFileRepository;