import React, { useState } from "react";
import useT  from '../../../Traduction';

const FileOutputOverview = ({ catalogAlreadyExist, fileOutput }) => {
  const t = useT();
  // eslint-disable-next-line
  const [editedCatalogName, setEditedCatalogName] = useState("");
  const handleInputChangeCatalogName = (event, key, subKey) => {
    setEditedCatalogName(fileOutput[key][subKey] = event.target.value.toUpperCase());
  };

  return (
    <div>
      <h4 className="text-center mb-5">{t("Output_file")}</h4>
      <div className="row">
        {Object.entries(fileOutput).map(([key, value]) => (
          <div className="col-md-4 mb-5" key={key}>
            {Object.entries(value).map(([fileOutputKey, fileOutputValue]) => (
              <React.Fragment key={fileOutputKey}>
                {fileOutputKey === "catalog_name" && (
                  <>
                  {!catalogAlreadyExist ? (
                    <div>
                      <strong>{t("Catalog_name")}: </strong>{fileOutputValue}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <strong>{t("Catalog_name")}: </strong>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("Catalog_name")}
                        value={fileOutput[key].catalog_name}
                        onChange={(event) => handleInputChangeCatalogName(event, key, "catalog_name")}
                      />
                    </div>
                  )}
                </>
                )}
                {fileOutputKey === "output_type" && (
                  <div>
                    <strong>{t("Output_type")}:</strong> {fileOutputValue.toUpperCase()}
                  </div>
                )}
                {fileOutputKey === "finished_product" && (
                  <div>
                    <strong>{t("Finished_product")}:</strong> {typeof fileOutputValue === 'boolean' ? (fileOutputValue ? t("Yes") : t("No")) : t(fileOutputValue)}
                  </div>
                )}
                {fileOutputKey === "sort" && (
                  <div>
                    <strong>{t("Sort")}:</strong> {typeof fileOutputValue === 'boolean' ? (fileOutputValue ? t("Yes") : t("No")) : t(fileOutputValue)}
                  </div>
                )}
                {fileOutputKey === "country" && (
                  <div>
                    <strong>{t("Country")}:</strong> {fileOutputValue.toUpperCase()}
                  </div>
                )}
                {fileOutputKey === "language" && (
                  <div>
                    <strong>{t("Language")}:</strong> {fileOutputValue.toUpperCase()}
                  </div>
                )}
                {fileOutput[key].sort && fileOutputKey === "sort_by" && (
                  <div>
                    <strong>{t("Sort_by")}:</strong> {t("data_reference_" + fileOutputValue)}
                  </div>
                )}
                {fileOutput[key].output_type === "DBF" && fileOutputKey === "exclude_column" && (
                  <div>
                    <strong>{t("Exclude_column")}:</strong> {fileOutputValue}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileOutputOverview;
